// Copyright text placeholder, Warner Bros. Discovery, Inc.

export function createKeyPressAlert(): { onKeyUp: (ev: KeyboardEvent) => void } {
  const containerId = 'key-up-container';
  let timeout = 0;

  const container = document.createElement('div');
  container.id = containerId;
  document.body.appendChild(container);

  const hide = (): void => {
    container.style.display = 'none';
  };

  const show = (keyCode: number): void => {
    container.textContent = `Key code: ${keyCode}`;
    container.style.display = 'block';
    window.clearTimeout(timeout);
    timeout = window.setTimeout(hide, 5000);
  };

  const onKeyUp = (ev: KeyboardEvent): void => {
    show(ev.keyCode);
    ev.preventDefault();
  };

  return { onKeyUp };
}
