/* eslint-disable header/header */
interface ICustomDOMException extends Error {
  code?: number;
}

interface IWebkitHTMLVideoElement extends HTMLVideoElement {
  canPlayType(type: string, keysystem?: string): CanPlayTypeResult;
}

/*! @license
 * Shaka Player
 * Copyright 2016 Google LLC
 * SPDX-License-Identifier: Apache-2.0
 */

/* This polyfill is build from https://github.com/google/shaka-player/blob/v3.2.1/lib/polyfill/patchedmediakeys_webkit.js */
export class PatchedMediaKeySystemAccess {
  public keySystem: string;
  private _internalKeySystem: string;
  private _configuration: MediaKeySystemConfiguration;

  public constructor(keySystem: string, supportedConfigurations: MediaKeySystemConfiguration[]) {
    this.keySystem = keySystem;
    this._internalKeySystem = keySystem;

    // This is only a guess, since we don't really know from the prefixed API.
    let allowPersistentState = false;
    if (keySystem === 'org.w3.clearkey') {
      // ClearKey's string must be prefixed in v0.1b.
      this._internalKeySystem = 'webkit-org.w3.clearkey';
      // ClearKey doesn't support persistence.
      allowPersistentState = false;
    }
    let resolved = false;

    const tmpVideo: IWebkitHTMLVideoElement = document.createElement('video');
    for (const config of supportedConfigurations) {
      // v0.1b tests for key system availability with an extra argument on canPlayType.

      // Create a new config object and start adding in the pieces which we
      // find support for.  We will return this from getConfiguration() if
      // asked.
      const newConfig: MediaKeySystemConfiguration = {
        audioCapabilities: [],
        videoCapabilities: [],
        persistentState: 'optional',
        distinctiveIdentifier: 'optional',
        initDataTypes: config.initDataTypes,
        sessionTypes: ['temporary'],
        label: config.label
      };

      let hasRunTests = false;

      if (config.audioCapabilities) {
        for (const cap of config.audioCapabilities) {
          if (cap.contentType) {
            hasRunTests = true;

            // In Chrome <= 40, if you ask about Widevine-encrypted audio
            // support, you get a false-negative when you specify codec
            // information. Work around this by stripping codec info for audio
            // types.
            const contentType = cap.contentType.split(';')[0];

            if (tmpVideo.canPlayType(contentType, this._internalKeySystem)) {
              newConfig.audioCapabilities?.push(cap);
              resolved = true;
            }
          }
        }
      }

      if (config.videoCapabilities) {
        for (const cap of config.videoCapabilities) {
          if (cap.contentType) {
            hasRunTests = true;
            if (tmpVideo.canPlayType(cap.contentType, this._internalKeySystem)) {
              newConfig.videoCapabilities?.push(cap);
              resolved = true;
            }
          }
        }
      }

      if (!hasRunTests) {
        // If no specific types were requested, we check all common types to
        // find out if the key system is present at all.
        resolved =
          !!tmpVideo.canPlayType('video/mp4', this._internalKeySystem) ||
          !!tmpVideo.canPlayType('video/webm', this._internalKeySystem);
      }

      if (config.persistentState === 'required') {
        if (allowPersistentState) {
          newConfig.persistentState = 'required';
          newConfig.sessionTypes = ['persistent-license'];
        } else {
          resolved = false;
        }
      }

      if (resolved) {
        this._configuration = newConfig;
        return;
      }
    }
    // for each config in supportedConfigurations
    let errorMsg = 'Unsupported keySystem';
    if (keySystem === 'org.w3.clearkey' || keySystem === 'com.widevine.alpha') {
      errorMsg = 'None of the requested configurations were supported.';
    }

    const unsupportedError: ICustomDOMException = new Error(errorMsg);
    unsupportedError.name = 'NotSupportedError';
    unsupportedError.code = DOMException.NOT_SUPPORTED_ERR;
    throw unsupportedError;
  }

  public createMediaKeys(): Promise<MediaKeys> {
    return Promise.resolve<MediaKeys>({
      createSession(sessionType?: MediaKeySessionType): MediaKeySession {
        throw new Error('function not supported');
      },
      setServerCertificate(serverCertificate: BufferSource): Promise<boolean> {
        throw new Error('function not supported');
      }
    } as MediaKeys);
  }

  public getConfiguration(): MediaKeySystemConfiguration {
    return this._configuration;
  }
}

export function patchedRequestMediaKeySystemAccess(
  keySystem: string,
  supportedConfigurations: MediaKeySystemConfiguration[]
): Promise<MediaKeySystemAccess> {
  try {
    const mediaAccess: MediaKeySystemAccess = new PatchedMediaKeySystemAccess(
      keySystem,
      supportedConfigurations
    );
    return Promise.resolve<MediaKeySystemAccess>(mediaAccess);
  } catch (exception) {
    return Promise.reject(exception);
  }
}
