/* eslint-disable tsdoc/syntax */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @rushstack/no-new-null */
/* eslint-disable header/header */

/**
 * @license Python-Software-Foundation-License
 *
 * Derived from 'Lib/test/pystone.py' from the Python software distribution.
 * @see https://svn.python.org/projects/python/trunk/Lib/test/pystone.py
 *
 * The python source is licensed under the PSF license
 * @see https://docs.python.org/3/license.html
 *
 * Dhrystone Benchmark original Author:
 * Reinhold P. Weicker,  CACM Vol 27, No 10, 10/84 pg. 1013
 *
 * Translated from C to Python by Guido van Rossum.
 */

const Ident1: number = 1,
  Ident2: number = 2,
  Ident3: number = 3,
  Ident4: number = 4,
  Ident5: number = 5;

class RecordClass {
  public PtrComp: RecordClass | null;
  public Discr: number;
  public EnumComp: number;
  public IntComp: number;
  public StringComp: string;

  public constructor(
    ptrComp?: RecordClass | null,
    discr?: number,
    enumComp?: number,
    intComp?: number,
    stringComp?: string
  ) {
    this.PtrComp = ptrComp || null;
    this.Discr = discr || 0;
    this.EnumComp = enumComp || 0;
    this.IntComp = intComp || 0;
    this.StringComp = stringComp || '';
  }

  public copy(): RecordClass {
    return new RecordClass(this.PtrComp, this.Discr, this.EnumComp, this.IntComp, this.StringComp);
  }
}

const DEFAULT_LOOPS: number = 5000000;

export function cpu_benchmark(loops: number = DEFAULT_LOOPS): number {
  const r = jsstones(loops),
    benchtime = r[0],
    stones = r[1];
  console.log('jsstones() time for ' + loops + ' passes = ' + benchtime);
  console.log('This device benchmarks at ' + stones + ' jsstones/second');
  return Math.round(stones);
}

function jsstones(loops: number): number[] {
  return Proc0(loops);
}

let IntGlob: number = 0;
let BoolGlob: number = +false;
let Char1Glob: string = '\0';
let Char2Glob: string = '\0';
const Array1Glob: number[] = new Array(51);
for (let i: number = 0; i < Array1Glob.length; ++i) {
  Array1Glob[i] = 0;
}
const Array2Glob: number[][] = Array1Glob.map((n) => {
  return Array1Glob.slice();
});
let PtrGlb: RecordClass | null = null;
let PtrGlbNext: RecordClass | null = null;

function clock(): number {
  return new Date().valueOf() / 1000.0;
}

function Proc0(loops: number): number[] {
  let starttime = clock();
  for (
    let i = 0;
    i < loops;
    ++i
    /* nothing*/
  );
  const nulltime = clock() - starttime;

  PtrGlbNext = new RecordClass();
  PtrGlb = new RecordClass();
  PtrGlb.PtrComp = PtrGlbNext;
  PtrGlb.Discr = Ident1;
  PtrGlb.EnumComp = Ident3;
  PtrGlb.IntComp = 40;
  PtrGlb.StringComp = 'DHRYSTONE PROGRAM, SOME STRING';
  const String1Loc = "DHRYSTONE PROGRAM, 1'ST STRING";
  Array2Glob[8][7] = 10;

  starttime = clock();

  for (let i = 0; i < loops; ++i) {
    Proc5();
    Proc4();
    let IntLoc1 = 2;
    let IntLoc2 = 3;
    let IntLoc3 = 0;
    const String2Loc = "DHRYSTONE PROGRAM, 2'ND STRING";
    let EnumLoc = Ident2;
    BoolGlob = +!Func2(String1Loc, String2Loc);
    while (IntLoc1 < IntLoc2) {
      IntLoc3 = 5 * IntLoc1 - IntLoc2;
      IntLoc3 = Proc7(IntLoc1, IntLoc2);
      IntLoc1 = IntLoc1 + 1;
    }
    Proc8(Array1Glob, Array2Glob, IntLoc1, IntLoc3);
    PtrGlb = Proc1(PtrGlb);
    let CharIndex = 'A';
    while (CharIndex <= Char2Glob) {
      if (EnumLoc === Func1(CharIndex, 'C')) {
        EnumLoc = Proc6(Ident1);
      }
      CharIndex = String.fromCharCode(CharIndex.charCodeAt(0) + 1);
    }
    IntLoc3 = IntLoc2 * IntLoc1;
    IntLoc2 = IntLoc3 / IntLoc1;
    IntLoc2 = 7 * (IntLoc3 - IntLoc2) - IntLoc1;
    IntLoc1 = Proc2(IntLoc1);
  }

  const benchtime = clock() - starttime - nulltime;
  let loopsPerBenchtime;
  if (benchtime === 0.0) {
    loopsPerBenchtime = 0.0;
  } else {
    loopsPerBenchtime = loops / benchtime;
  }
  return [benchtime, loopsPerBenchtime];
}

function Proc1(PtrParIn: RecordClass): RecordClass {
  let NextRecordClass;
  PtrParIn.PtrComp = NextRecordClass = PtrGlb!.copy();
  PtrParIn.IntComp = 5;
  NextRecordClass.IntComp = PtrParIn.IntComp;
  NextRecordClass.PtrComp = PtrParIn.PtrComp;
  NextRecordClass.PtrComp = Proc3(NextRecordClass.PtrComp);
  if (NextRecordClass.Discr === Ident1) {
    NextRecordClass.IntComp = 6;
    NextRecordClass.EnumComp = Proc6(PtrParIn.EnumComp);
    NextRecordClass.PtrComp = PtrGlb!.PtrComp;
    NextRecordClass.IntComp = Proc7(NextRecordClass.IntComp, 10);
  } else {
    PtrParIn = NextRecordClass.copy();
  }
  NextRecordClass.PtrComp = null;
  return PtrParIn;
}

function Proc2(IntParIO: number): number {
  let IntLoc = IntParIO + 10;
  let EnumLoc;
  // eslint-disable-next-line no-constant-condition
  while (true) {
    if (Char1Glob === 'A') {
      IntLoc = IntLoc - 1;
      IntParIO = IntLoc - IntGlob;
      EnumLoc = Ident1;
    }
    if (EnumLoc === Ident1) break;
  }
  return IntParIO;
}

function Proc3(PtrParOut: RecordClass): RecordClass {
  if (PtrGlb !== null) {
    PtrParOut = PtrGlb.PtrComp!;
  } else {
    IntGlob = 100;
  }
  PtrGlb!.IntComp = Proc7(10, IntGlob);
  return PtrParOut;
}

function Proc4(): void {
  let BoolLoc = Char1Glob === 'A';
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  BoolLoc = BoolLoc || Boolean(BoolGlob);
  Char2Glob = 'B';
}

function Proc5(): void {
  Char1Glob = 'A';
  BoolGlob = +false;
}

function Proc6(EnumParIn: number): number {
  let EnumParOut = EnumParIn;
  if (!Func3(EnumParIn)) {
    EnumParOut = Ident4;
  }

  if (EnumParIn === Ident1) {
    EnumParOut = Ident1;
  } else if (EnumParIn === Ident2) {
    if (IntGlob > 100) {
      EnumParOut = Ident1;
    } else {
      EnumParOut = Ident4;
    }
  } else if (EnumParIn === Ident3) {
    EnumParOut = Ident2;
  } else if (EnumParIn === Ident4) {
    // pass
    // eslint-disable-next-line no-unused-expressions
    null;
  } else if (EnumParIn === Ident5) {
    EnumParOut = Ident3;
  }
  return EnumParOut;
}

function Proc7(IntParI1: number, IntParI2: number): number {
  const IntLoc = IntParI1 + 2;
  const IntParOut = IntParI2 + IntLoc;
  return IntParOut;
}

function Proc8(Array1Par: number[], Array2Par: number[][], IntParI1: number, IntParI2: number): void {
  const IntLoc = IntParI1 + 5;
  Array1Par[IntLoc] = IntParI2;
  Array1Par[IntLoc + 1] = Array1Par[IntLoc];
  Array1Par[IntLoc + 30] = IntLoc;
  // for IntIndex in range(IntLoc, IntLoc+2):
  for (let IntIndex = IntLoc; IntIndex < IntLoc + 2; ++IntIndex) {
    Array2Par[IntLoc][IntIndex] = IntLoc;
  }
  Array2Par[IntLoc][IntLoc - 1] = Array2Par[IntLoc][IntLoc - 1] + 1;
  Array2Par[IntLoc + 20][IntLoc] = Array1Par[IntLoc];
  IntGlob = 5;
}

function Func1(CharPar1: string, CharPar2: string): number {
  const CharLoc1 = CharPar1;
  const CharLoc2 = CharLoc1;
  if (CharLoc2 !== CharPar2) {
    return Ident1;
  } else {
    return Ident2;
  }
}

function Func2(StrParI1: string, StrParI2: string): number {
  let IntLoc = 1;
  let CharLoc: string = '';
  while (IntLoc <= 1) {
    if (Func1(StrParI1[IntLoc], StrParI2[IntLoc + 1]) === Ident1) {
      CharLoc = 'A';
      IntLoc = IntLoc + 1;
    }
  }
  if (CharLoc >= 'W' && CharLoc <= 'Z') {
    IntLoc = 7;
  }
  if (CharLoc === 'X') {
    return +true;
  } else {
    if (StrParI1 > StrParI2) {
      IntLoc = IntLoc + 7;
      return +true;
    } else {
      return +false;
    }
  }
}

function Func3(EnumParIn: number): number {
  const EnumLoc = EnumParIn;
  if (EnumLoc === Ident3) return +true;
  return +false;
}
