// Copyright text placeholder, Warner Bros. Discovery, Inc.

import after from 'lodash/after';
import { patchedRequestMediaKeySystemAccess } from '../polyfill/PatchedMediaKeySystemAccess';

enum EmeVersion {
  SUPPORTED = 'true',
  LEGACY_WEBKIT = 'webkit-prefixed EME v0.1b',
  LEGACY = 'non-prefixed EME v0.1b',
  NOT_SUPPORTED = 'false'
}

export const hasEMESupport = function (): EmeVersion {
  const eme = 'MediaKeys' in window || 'WebKitMediaKeys' in window || 'MSMediaKeys' in window;
  if (eme) return EmeVersion.SUPPORTED;

  // @ts-ignore:next-line
  const legacyWebkitGenerateKey = !!HTMLMediaElement.prototype.webkitGenerateKeyRequest;
  if (legacyWebkitGenerateKey) return EmeVersion.LEGACY_WEBKIT;

  // @ts-ignore:next-line
  const legacyGenerateKey = !!HTMLMediaElement.prototype.generateKeyRequest;
  if (legacyGenerateKey) return EmeVersion.LEGACY;

  return EmeVersion.NOT_SUPPORTED;
};

export const hasRMKSASupport = function (): boolean {
  const requestMediaKeySystemAccess =
    'requestMediaKeySystemAccess' in window.navigator && MediaKeySystemAccess.prototype.getConfiguration;
  if (requestMediaKeySystemAccess) return true;

  const emeVersion = hasEMESupport();
  if (emeVersion === EmeVersion.LEGACY || emeVersion === EmeVersion.LEGACY_WEBKIT) return true;

  return false;
};

export const hasDRMSupport = function (callback: (supportedKeySystems: string[]) => void): void {
  const emeVersion = hasEMESupport();

  // cancel if we have no EME support
  if (emeVersion === EmeVersion.NOT_SUPPORTED) return callback([]);

  const mediaKeySystemAccess =
    emeVersion === EmeVersion.SUPPORTED
      ? navigator.requestMediaKeySystemAccess.bind(navigator)
      : patchedRequestMediaKeySystemAccess;

  const supportedKeySystems: string[] = [];
  const keysSystems = ['com.widevine.alpha', 'com.microsoft.playready', 'com.apple.fps'];

  const config = [
    {
      initDataTypes: ['cenc'],
      audioCapabilities: [
        {
          contentType: 'audio/mp4;codecs="mp4a.40.2"'
        }
      ],
      videoCapabilities: [
        {
          contentType: 'video/mp4;codecs="avc1.4d400d"'
        }
      ]
    }
  ];

  const keySystemCallback = after(keysSystems.length, () => {
    callback(supportedKeySystems);
  });

  keysSystems.forEach((keysSystem) => {
    try {
      mediaKeySystemAccess(keysSystem, config)
        .then((system: MediaKeySystemAccess) => {
          // create media key to validate CDM works
          system
            .createMediaKeys()
            .then(() => {
              supportedKeySystems.push(keysSystem);
              keySystemCallback();
            })
            .catch(() => {
              keySystemCallback();
            });
        })
        .catch((e) => {
          keySystemCallback();
        });
    } catch (e) {
      keySystemCallback();
    }
  });
};
