// Copyright text placeholder, Warner Bros. Discovery, Inc.

// basic polyfill for promises only
import 'core-js/features/promise';

import './codegen/modernizr';
import './styles/app.css';

import { loadJSONP, setResultForElement } from './utils';
import { cpu_benchmark } from './validations/cpu';
import { IHowsMySSLCallback } from './validations/ssl';
import { hasDRMSupport, hasEMESupport } from './validations/video';
import { hasWebGLSupport } from './validations/webgl';
import { createKeyPressAlert } from './keys/createKeyPressAlert';

async function doTests(): Promise<void> {
  const viewportWidth = Math.round(window.innerWidth || document.documentElement.clientWidth);
  const viewportHeight = Math.round(window.innerHeight || document.documentElement.clientHeight);
  const hardwareConcurrency = 'hardwareConcurrency' in navigator ? navigator.hardwareConcurrency : '';

  setResultForElement('result-viewport', `${viewportWidth}x${viewportHeight}`);
  setResultForElement('result-user-agent', navigator.userAgent);
  setResultForElement('result-cors', `${Modernizr.cors}`);
  setResultForElement('result-canvas', `${Modernizr.canvas}`);
  setResultForElement('result-canvas-text', `${Modernizr.canvastext}`);
  setResultForElement('result-webgl', `${hasWebGLSupport()}`);
  setResultForElement('result-cookies', `${Modernizr.cookies}`);
  setResultForElement('result-localstorage', `${Modernizr.localstorage}`);
  setResultForElement('result-history', `${Modernizr.history}`);
  setResultForElement('result-hash', `${Modernizr.hashchange}`);
  setResultForElement('result-fetch', `${Modernizr.fetch}`);
  setResultForElement('result-requestanimationframe', `${Modernizr.requestanimationframe}`);
  setResultForElement('result-svg', `${Modernizr.svg}`);
  setResultForElement('result-webworker', `${Modernizr.webworkers}`);
  setResultForElement('result-fontface', `${Modernizr.fontface}`);
  setResultForElement('result-mse', `${Modernizr.mediasource}`);
  setResultForElement('result-eme', `${hasEMESupport()}`);
  setResultForElement('result-concurrency', `${hardwareConcurrency}`);

  // async tests
  // keeping code as simple as possible by avoiding promises / polyfills requirements
  hasDRMSupport((supportedKeySystems) => {
    setResultForElement('result-drm', `${supportedKeySystems.join(', ')}`);
  });
  loadJSONP('https://www.howsmyssl.com/a/check', (data: IHowsMySSLCallback) => {
    setResultForElement('result-tls', `${data.tls_version}`);
  });

  // artificially delay drystone CPU test until the rest of the app is loaded
  setTimeout(() => {
    const jsstones = cpu_benchmark();
    setResultForElement('result-jsstones', `${jsstones} jsstones / second`);
  }, 10_000);
}

window.addEventListener('load', doTests, false);

const { onKeyUp } = createKeyPressAlert();
window.addEventListener('keyup', onKeyUp);
