// Copyright text placeholder, Warner Bros. Discovery, Inc.

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    jsonpCallback: (value: never) => void;
  }
}

export const loadJSONP = function (
  src: string,
  callback: (value: never) => void,
  callbackParam: string = 'callback'
): void {
  window.jsonpCallback = callback;
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = `${src}?${callbackParam}=jsonpCallback`;
  document.body.appendChild(script);
  document.body.removeChild(script);
};

export const setResultForElement = function (id: string, result: string): void {
  const el = document.getElementById(id);
  if (el) el.innerHTML = result;
};
