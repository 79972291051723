// Copyright text placeholder, Warner Bros. Discovery, Inc.

export const hasWebGLSupport = function (): boolean {
  // base webGL validation
  if (!Modernizr.webgl) return false;

  // advanced lightning specific webGL validation
  const opts: { [key: string]: boolean } = {
    alpha: true,
    antialias: false,
    premultipliedAlpha: true,
    stencil: true,
    preserveDrawingBuffer: false
  };
  try {
    const canvas = document.createElement('canvas');
    const gl = canvas.getContext('webgl', opts) || canvas.getContext('experimental-webgl', opts);
    return !!gl;
  } catch (error) {
    return false;
  }
};
